var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "projects__table"
  }, [_c('el-table', {
    staticClass: "desktop",
    attrs: {
      "data": _vm.projectsList,
      "row-class-name": _vm.tableRowClassName
    },
    on: {
      "header-click": _vm.sortData
    }
  }, [_c('el-table-column', {
    attrs: {
      "index": 0,
      "label-class-name": _vm.typeSort === 'title' ? 'sortable ' + _vm.sortOrder : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "projects__table-item"
        }, [_c('span', {
          staticClass: "projects__table-item--bold",
          on: {
            "click": function click($event) {
              return _vm.getDetailProject(scope.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.title) + " ")]), _c('br'), _c('div', {
          staticClass: "hide-sm  hide-xs"
        }, [_vm._v(" " + _vm._s(scope.row.address) + " "), _vm.tooltipText(scope.row).length ? _c('el-tooltip', {
          staticClass: "projects__tooltip",
          attrs: {
            "effect": "light",
            "placement": "bottom"
          }
        }, [_c('div', {
          attrs: {
            "slot": "content"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.tooltipText(scope.row))
          },
          slot: "content"
        }), _c('div', [_vm._v("!")])]) : _vm._e()], 1)])];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Название "), _c('svg', {
    attrs: {
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 5H0L3 9L6 5H3Z",
      "fill": "#A2A7BE"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 4L6 4L3 0L0 4L3 4Z",
      "fill": "#A2A7BE"
    }
  })])])], 2), _c('el-table-column', {
    attrs: {
      "width": "150",
      "index": 1,
      "label": "Начало работ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.start_work ? [_vm._v(" " + _vm._s(_vm.fixDate(scope.row.start_work)) + " "), scope.row.end_work ? _c('span', {
          staticClass: "hide-lg  hide-md"
        }, [_vm._v(" - " + _vm._s(_vm.fixDate(scope.row.end_work)) + " ")]) : _vm._e(), scope.row.address ? _c('div', {
          staticClass: "hide-lg  hide-md"
        }, [_vm._v(" " + _vm._s(scope.row.address) + " ")]) : _vm._e()] : undefined;
      }
    }], null, true)
  }), _c('el-table-column', {
    attrs: {
      "width": "150",
      "index": 2,
      "label": "Окончание работ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.end_work ? [_vm._v(" " + _vm._s(_vm.fixDate(scope.row.end_work)) + " ")] : undefined;
      }
    }], null, true)
  }), _c('el-table-column', {
    attrs: {
      "index": 3,
      "prop": "description",
      "label": "Описание проекта"
    }
  }), _c('el-table-column', {
    attrs: {
      "width": "100",
      "index": 4,
      "prop": "description",
      "label": "Статус"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "projects__table-status",
          class: {
            active: scope.row.is_complete
          }
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "width": "150",
      "index": 5
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "projects__table-employees"
        }, [_c('div', {
          staticClass: "projects__table-employees__hired",
          domProps: {
            "textContent": _vm._s(scope.row.active_vacancies_count)
          }
        }), _c('div', {
          staticClass: "projects__table-employees__needed",
          domProps: {
            "textContent": _vm._s(scope.row.vacancies_count)
          }
        }), _vm.currentTariff.can_download_project_stats || _vm.userRole === 'admin' ? _c('download-report', {
          key: scope.row.id,
          attrs: {
            "id": scope.row.id,
            "name": scope.row.title,
            "title": "Выгрузить статистику по проекту за период:"
          },
          on: {
            "download": _vm.downloadProjectReport
          }
        }) : _vm._e()], 1)];
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_vm._v(" Работники "), _c('span', {
    staticClass: "table-th--subtitle"
  }, [_vm._v("Работают/Всего")])])], 2), _vm.forSpeciality ? _c('el-table-column', {
    attrs: {
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('label', {
          staticClass: "el-checkbox",
          class: {
            'is-checked': scope.row.id === _vm.selectedProject
          }
        }, [_c('span', {
          staticClass: "el-checkbox__input",
          class: {
            'is-checked': scope.row.id === _vm.selectedProject
          }
        }, [_c('span', {
          staticClass: "el-checkbox__inner"
        }), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.selectedProject,
            expression: "selectedProject"
          }],
          staticClass: "el-checkbox__original",
          attrs: {
            "type": "radio",
            "aria-hidden": "false"
          },
          domProps: {
            "value": scope.row.id,
            "checked": _vm._q(_vm.selectedProject, scope.row.id)
          },
          on: {
            "change": [function ($event) {
              _vm.selectedProject = scope.row.id;
            }, function ($event) {
              return _vm.$emit('changed', _vm.selectedProject);
            }]
          }
        })])])];
      }
    }], null, false, 1444260284)
  }) : _c('el-table-column', {
    attrs: {
      "index": 6,
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "projects__table-actions"
        }, [_vm.user.role !== 'admin' ? _c('div', {
          staticClass: "projects__table-actions__chat",
          on: {
            "click": function click($event) {
              return _vm.openChat(scope.row.id, scope.row.title);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-chat.svg")
          }
        }), _vm.getCount(scope.row.id) ? _c('span', {
          staticClass: "projects__table-actions__chat-count",
          domProps: {
            "textContent": _vm._s(_vm.getCount(scope.row.id))
          }
        }) : _vm._e()]) : _vm._e(), _c('el-dropdown', {
          staticClass: "hide-sm  hide-xs",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('span', {
          staticClass: "el-dropdown-link"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-option.svg")
          }
        })]), _c('el-dropdown-menu', {
          staticClass: "projects__options",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'more',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-info.svg")
          }
        }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              name: 'block',
              id: scope.row.id,
              status: scope.row.is_complete
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-edit.svg")
          }
        }), _vm._v(" Изменить статус ")]), _c('el-dropdown-item', {
          staticClass: "removed",
          attrs: {
            "command": {
              name: 'delete',
              id: scope.row.id
            }
          }
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/icons/icon-removed-red.svg")
          }
        }), _vm._v(" Удалить ")])], 1)], 1)], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "mobile"
  }, _vm._l(_vm.projectsList, function (row) {
    return _c('div', {
      key: row.id,
      staticClass: "card"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Название")]), _c('div', {
      staticClass: "col"
    }, [_c('router-link', {
      attrs: {
        "to": "/projects/".concat(row.id)
      }
    }, [_c('b', [_vm._v(_vm._s(row.title))])]), _vm._v(" : " + _vm._s(row.address) + " ")], 1)]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Начало работ")]), _c('div', {
      staticClass: "col"
    }, [_vm._v(" " + _vm._s(_vm.fixDate(row.start_work)) + " ")])]), row.end_work ? _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v(" Окончание работ ")]), _c('div', {
      staticClass: "col"
    }, [_vm._v(" " + _vm._s(_vm.fixDate(row.end_work)) + " ")])]) : _vm._e(), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col"
    }, [_vm._v("Статус")]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "projects__table-status",
      class: {
        active: row.is_complete
      }
    })])]), _c('div', {
      staticClass: "row row--center"
    }, [_c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "projects__table-employees"
    }, [_c('div', {
      staticClass: "projects__table-employees__hired",
      domProps: {
        "textContent": _vm._s(row.active_vacancies_count)
      }
    }), _c('div', {
      staticClass: "projects__table-employees__needed",
      domProps: {
        "textContent": _vm._s(row.vacancies_count)
      }
    }), _vm.currentTariff.can_download_project_stats || _vm.userRole === 'admin' ? _c('download-report', {
      key: row.id,
      attrs: {
        "id": row.id,
        "name": row.title,
        "title": "Выгрузить статистику по проекту за период:"
      },
      on: {
        "download": _vm.downloadProjectReport
      }
    }) : _vm._e()], 1)]), _c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "projects__table-actions"
    }, [_vm.user.role !== 'admin' ? _c('div', {
      staticClass: "projects__table-actions__chat",
      on: {
        "click": function click($event) {
          return _vm.openChat(row.id, row.title);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-chat.svg")
      }
    }), _vm.getCount(row.id) ? _c('span', {
      staticClass: "projects__table-actions__chat-count",
      domProps: {
        "textContent": _vm._s(_vm.getCount(row.id))
      }
    }) : _vm._e()]) : _vm._e(), _c('div', {
      staticClass: "more-options"
    }, [_c('span', {
      staticClass: "el-dropdown-link",
      on: {
        "click": function click($event) {
          _vm.visibleMoreOptions = true;
          _vm.activeProject = row;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/icons/icon-option.svg")
      }
    })])])])])])]);
  }), 0), _c('div', {
    staticClass: "projects__nav"
  }, [_c('Pagination', {
    attrs: {
      "info": _vm.pagination
    },
    on: {
      "changedPage": _vm.changePage
    }
  })], 1), _c('el-dialog', {
    attrs: {
      "title": "Действия",
      "visible": _vm.visibleMoreOptions,
      "width": "288px",
      "custom-class": "more-options"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleMoreOptions = $event;
      }
    }
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        _vm.visibleMoreOptions = false;

        _vm.handleCommand({
          name: 'more',
          id: _vm.activeProject.id
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-info.svg")
    }
  }), _vm._v(" Подробнее ")]), _c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        _vm.visibleMoreOptions = false;

        _vm.handleCommand({
          name: 'block',
          id: _vm.activeProject.id,
          status: _vm.activeProject.is_complete
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-edit.svg")
    }
  }), _vm._v(" Изменить статус ")]), _c('el-dropdown-item', {
    staticClass: "removed",
    nativeOn: {
      "click": function click($event) {
        _vm.visibleMoreOptions = false;

        _vm.handleCommand({
          name: 'delete',
          id: _vm.activeProject.id
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/icon-removed-red.svg")
    }
  }), _vm._v(" Удалить ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }