<template>
  <div class="projects__table">
    <el-table
      class="desktop"
      :data="projectsList"
      :row-class-name="tableRowClassName"
      @header-click="sortData"
    >
      <el-table-column
        :index="0"
        :label-class-name="typeSort === 'title' ? 'sortable ' + sortOrder : ''"
      >
        <template slot="header">
          Название
          <svg
            width="6"
            height="9"
            viewBox="0 0 6 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 5H0L3 9L6 5H3Z" fill="#A2A7BE" />
            <path d="M3 4L6 4L3 0L0 4L3 4Z" fill="#A2A7BE" />
          </svg>
        </template>
        <template slot-scope="scope">
          <div class="projects__table-item">
            <span
              class="projects__table-item--bold"
              @click="getDetailProject(scope.row.id)"
            >
              {{ scope.row.title }}
            </span>
            <br />
            <div class="hide-sm  hide-xs">
              {{ scope.row.address }}
              <el-tooltip
                v-if="tooltipText(scope.row).length"
                class="projects__tooltip"
                effect="light"
                placement="bottom"
              >
                <div slot="content" v-html="tooltipText(scope.row)" />
                <div>!</div>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150" :index="1" label="Начало работ">
        <template v-if="scope.row.start_work" slot-scope="scope">
          {{ fixDate(scope.row.start_work) }}
          <span v-if="scope.row.end_work" class="hide-lg  hide-md">
            -
            {{ fixDate(scope.row.end_work) }}
          </span>
          <div v-if="scope.row.address" class="hide-lg  hide-md">
            {{ scope.row.address }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="150" :index="2" label="Окончание работ">
        <template v-if="scope.row.end_work" slot-scope="scope">
          {{ fixDate(scope.row.end_work) }}
        </template>
      </el-table-column>
      <el-table-column :index="3" prop="description" label="Описание проекта" />
      <el-table-column width="100" :index="4" prop="description" label="Статус">
        <template slot-scope="scope">
          <div
            class="projects__table-status"
            :class="{ active: scope.row.is_complete }"
          />
        </template>
      </el-table-column>
      <el-table-column width="150" :index="5">
        <template slot="header">
          Работники <span class="table-th--subtitle">Работают/Всего</span>
        </template>
        <template slot-scope="scope">
          <div class="projects__table-employees">
            <div
              class="projects__table-employees__hired"
              v-text="scope.row.active_vacancies_count"
            />
            <div
              class="projects__table-employees__needed"
              v-text="scope.row.vacancies_count"
            />
            <download-report
              v-if="
                currentTariff.can_download_project_stats || userRole === 'admin'
              "
              :key="scope.row.id"
              :id="scope.row.id"
              :name="scope.row.title"
              title="Выгрузить статистику по проекту за период:"
              @download="downloadProjectReport"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="forSpeciality" width="100">
        <template slot-scope="scope">
          <label
            class="el-checkbox"
            :class="{ 'is-checked': scope.row.id === selectedProject }"
          >
            <span
              class="el-checkbox__input"
              :class="{ 'is-checked': scope.row.id === selectedProject }"
            >
              <span class="el-checkbox__inner"></span>
              <input
                type="radio"
                aria-hidden="false"
                class="el-checkbox__original"
                :value="scope.row.id"
                v-model="selectedProject"
                @change="$emit('changed', selectedProject)"
              />
            </span>
          </label>
        </template>
      </el-table-column>
      <el-table-column v-else :index="6" width="100">
        <template slot-scope="scope">
          <div class="projects__table-actions">
            <div
              v-if="user.role !== 'admin'"
              class="projects__table-actions__chat"
              @click="openChat(scope.row.id, scope.row.title)"
            >
              <img src="@/assets/icons/icon-chat.svg" />
              <span
                v-if="getCount(scope.row.id)"
                class="projects__table-actions__chat-count"
                v-text="getCount(scope.row.id)"
              />
            </div>
            <el-dropdown
              trigger="click"
              @command="handleCommand"
              class="hide-sm  hide-xs"
            >
              <span class="el-dropdown-link">
                <img src="@/assets/icons/icon-option.svg" />
              </span>
              <el-dropdown-menu slot="dropdown" class="projects__options">
                <el-dropdown-item :command="{ name: 'more', id: scope.row.id }">
                  <img src="@/assets/icons/icon-info.svg" />
                  Подробнее
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{
                    name: 'block',
                    id: scope.row.id,
                    status: scope.row.is_complete
                  }"
                >
                  <img src="@/assets/icons/icon-edit.svg" />
                  Изменить статус
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ name: 'delete', id: scope.row.id }"
                  class="removed"
                >
                  <img src="@/assets/icons/icon-removed-red.svg" />
                  Удалить
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mobile">
      <div v-for="row in projectsList" :key="row.id" class="card">
        <div class="row">
          <div class="col">Название</div>
          <div class="col">
            <router-link :to="`/projects/${row.id}`">
              <b>{{ row.title }}</b>
            </router-link>
            :
            {{ row.address }}
          </div>
        </div>
        <div class="row">
          <div class="col">Начало работ</div>
          <div class="col">
            {{ fixDate(row.start_work) }}
          </div>
        </div>
        <div v-if="row.end_work" class="row">
          <div class="col">
            Окончание работ
          </div>
          <div class="col">
            {{ fixDate(row.end_work) }}
          </div>
        </div>
        <div class="row">
          <div class="col">Статус</div>
          <div class="col">
            <div
              class="projects__table-status"
              :class="{ active: row.is_complete }"
            />
          </div>
        </div>
        <div class="row row--center">
          <div class="col">
            <div class="projects__table-employees">
              <div
                class="projects__table-employees__hired"
                v-text="row.active_vacancies_count"
              />
              <div
                class="projects__table-employees__needed"
                v-text="row.vacancies_count"
              />
              <download-report
                v-if="
                  currentTariff.can_download_project_stats ||
                    userRole === 'admin'
                "
                :key="row.id"
                :id="row.id"
                :name="row.title"
                title="Выгрузить статистику по проекту за период:"
                @download="downloadProjectReport"
              />
            </div>
          </div>
          <div class="col">
            <div class="projects__table-actions">
              <div
                v-if="user.role !== 'admin'"
                class="projects__table-actions__chat"
                @click="openChat(row.id, row.title)"
              >
                <img src="@/assets/icons/icon-chat.svg" />
                <span
                  v-if="getCount(row.id)"
                  class="projects__table-actions__chat-count"
                  v-text="getCount(row.id)"
                />
              </div>
              <div class="more-options">
                <span
                  @click="
                    visibleMoreOptions = true;
                    activeProject = row;
                  "
                  class="el-dropdown-link"
                >
                  <img src="@/assets/icons/icon-option.svg" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="projects__nav">
      <Pagination :info="pagination" @changedPage="changePage" />
    </div>
    <el-dialog
      title="Действия"
      :visible.sync="visibleMoreOptions"
      width="288px"
      custom-class="more-options"
    >
      <el-dropdown-item
        @click.native="
          visibleMoreOptions = false;
          handleCommand({ name: 'more', id: activeProject.id });
        "
      >
        <img src="@/assets/icons/icon-info.svg" />
        Подробнее
      </el-dropdown-item>
      <el-dropdown-item
        @click.native="
          visibleMoreOptions = false;
          handleCommand({
            name: 'block',
            id: activeProject.id,
            status: activeProject.is_complete
          });
        "
      >
        <img src="@/assets/icons/icon-edit.svg" />
        Изменить статус
      </el-dropdown-item>
      <el-dropdown-item
        @click.native="
          visibleMoreOptions = false;
          handleCommand({ name: 'delete', id: activeProject.id });
        "
        class="removed"
      >
        <img src="@/assets/icons/icon-removed-red.svg" />
        Удалить
      </el-dropdown-item>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "./Pagination";
import { downloadFile } from "@/utils/download";
import DownloadReport from "@/components/blocks/DownloadReport.vue";

export default {
  name: "ProjectsTable",
  components: {
    DownloadReport,
    Pagination
  },
  props: {
    selectedCompany: {
      type: String,
      default: ""
    },
    forSpeciality: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      typeSort: "",
      ascOrder: true,
      page: 1,
      pageCount: 10,
      selectedProject: "",
      visibleMoreOptions: false,
      activeProject: null
    };
  },
  computed: {
    projectsList() {
      return this.$store.state.Projects.projectsList?.map(item => ({
        ...item,
        visibleMoreOptions: false
      }));
    },
    pagination() {
      return this.$store.state.Projects.pagination;
    },
    successCreate() {
      return this.$store.state.Company.successCreate;
    },
    companiesList() {
      return this.$store.state.Projects.companiesList;
    },
    sortOrder() {
      let order = "asc";
      if (!this.ascOrder) order = "desc";
      return order;
    },
    currentCompanyOfUser() {
      return this.$store.state.Auth.currentCompanyOfUser;
    },
    user() {
      return this.$store.state.Auth.user;
    },
    ready() {
      return this.$store.state.Auth.ready;
    },
    allMessages() {
      return this.$store.getters["Chat/allMessages"];
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    },
    routeQuery() {
      return this.$route.query;
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    }
  },
  watch: {
    selectedCompany() {
      this.getProjects();
    },
    ready(val) {
      if (val) this.getProjects();
    },
    routeQuery: {
      deep: true,
      handler() {
        this.getProjects();
      }
    }
  },
  methods: {
    getCount(id) {
      let count = 0;
      if (this.allMessages && this.allMessages.length) {
        const elem = this.allMessages.find(el => el.id === id);
        if (elem) count = elem.count;
      }
      return count;
    },
    fixDate(val) {
      return val
        .split(" ")[0]
        .split("-")
        .reverse()
        .join(".");
    },
    tableRowClassName({ row }) {
      if (row.is_blocked) {
        return "blocked-row";
      }
      return "";
    },
    tooltipText({ end_work }) {
      let result = "";
      const date = new Date().getTime();
      if (end_work) {
        const permissionExpiration =
          new Date(end_work).getTime() - 10 * 24 * 60 * 60 * 1000;
        result += permissionExpiration <= date ? "Истекает срок проекта" : "";
      }
      return result;
    },
    getProjects() {
      let company = null;
      if (this.currentCompanyOfUser) {
        company = this.currentCompanyOfUser;
      } else {
        company = this.selectedCompany === "all" ? null : this.selectedCompany;
      }
      this.$store.dispatch("Projects/getProjects", {
        page: this.$route.query.page || this.page,
        pageCount: this.pageCount,
        sortField: this.typeSort ? this.typeSort : null,
        sortBy: this.typeSort ? this.sortOrder : null,
        company
      });
    },
    changePage(page, count) {
      this.page = page;
      this.pageCount = count;
      this.$router.push({ query: { page } });
    },
    getDetailProject(id) {
      this.$router.push({ path: "/projects/" + id });
    },
    handleCommand(command) {
      switch (command.name) {
        case "delete":
          this.removeProject(command.id);
          break;
        case "block":
          this.changeStatus(command.id, command.status);
          break;
        case "more":
          this.$router.push({ path: "/projects/" + command.id });
          break;
      }
    },
    removeProject(id) {
      this.$confirm(
        `<div class="remove-message">
        <img src="${require("@/assets/icons/icon-delete-red.svg")}">
        <div>
          <div class="remove-message__title">
            Удаление проекта
          </div>
          <div class="remove-message__text">
            К данному проекту прикреплены сотрудники, которые будут убраны из проекта при его удалении. Вы уверены, что хотите удалить проект?
          </div>
        </div>
      </div>`,
        {
          customClass: "remove-message__box",
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Удалить",
          cancelButtonText: "Отменить",
          showClose: false
        }
      ).then(() => {
        this.$store
          .dispatch("Projects/deleteProject", id)
          .then(() => {
            this.getProjects();
            this.$notify({
              title: "Успех",
              message: "Проект удален",
              type: "success"
            });
          })
          .catch(err => {
            console.log(err);
            this.$notify({
              title: "Ошибка",
              message: "Ошибка, попробуйте еще раз",
              type: "error"
            });
          });
      });
    },
    changeStatus(id, status) {
      const data = {
        is_complete: !status
      };
      this.$store
        .dispatch("Projects/changeStatus", { id, data })
        .then(() => {
          this.getProjects();
          this.$notify({
            title: "Успех",
            message: "Статус изменен",
            type: "success"
          });
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    },
    sortData(row) {
      switch (row.index) {
        case 0:
          this.typeSort === "title"
            ? (this.ascOrder = !this.ascOrder)
            : (this.ascOrder = true);
          this.typeSort = "title";
          break;
      }
      this.getProjects();
    },
    openChat(id, name) {
      this.$store.commit("Chat/setData", {
        label: "currentChat",
        data: { type: "project", id, name }
      });
      this.$store.commit("Option/setData", {
        label: "currentOption",
        data: "Chat"
      });
    },
    downloadProjectReport({ dates, id, name }) {
      const data = {
        start_date: dates[0],
        end_date: dates[1]
      };
      this.$store
        .dispatch("Projects/downloadProjectReport", { id, data })
        .then(data => {
          downloadFile(data, name);
          this.visible = false;
        })
        .catch(err => {
          console.log(err);
          this.$notify({
            title: "Ошибка",
            message: "Ошибка, попробуйте еще раз",
            type: "error"
          });
        });
    }
  },
  created() {
    if (this.ready) {
      this.getProjects();
    }
    this.$store.dispatch("Projects/getCompaniesList").then(() => {
      if (this.$route.query.company) {
        this.selectedCompany = this.$route.query.company;
      }
    });
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.projects {
  .card {
    margin: 0 14px 14px;
  }

  &__table {
    .cell {
      width: 90%;
    }

    &-status {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $error;

      &.active {
        background: $active;
      }
    }

    &-employees {
      display: flex;
      font-size: 1rem;
      line-height: 1.4rem;

      & > div {
        width: 3.6rem;
        height: 2.6rem;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__hired {
        background: rgba(104, 218, 136, 0.15);
        border: 1px solid $active;
        color: $active;
      }

      &__needed {
        background: rgba(213, 219, 232, 0.15);
        border: 1px solid $border;
        color: #adb2bb;
        margin-left: 0.8rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;

      &__chat {
        margin-right: 2rem;
        margin-top: 0.7rem;
        cursor: pointer;
        position: relative;

        &-count {
          position: absolute;
          right: -1px;
          top: -2px;
          height: 1.3rem;
          min-width: 1.3rem;
          font-size: 0.9rem;
          line-height: 1.1rem;
          text-align: center;
          color: #fff;
          padding: 0.1rem 0.2rem 0 0.3rem;
          border-radius: 50%;
          background-color: #dd4a64;
        }
      }
    }

    .el-table th {
      height: 5.8rem;
      padding: 0;
    }

    &-item {
      &--bold {
        font-weight: 600;
        color: $primary;
        cursor: pointer;

        &:hover {
          color: $text;
        }
      }
    }
  }

  &__tooltip {
    vertical-align: baseline;
    display: inline-block;
    text-align: center;
    width: 13px;
    height: 13px;
    color: #fff;
    border-radius: 50%;
    background: #dd4a64;
    margin-left: 4px;
    position: relative;
    top: -2px;
    font-size: 12px;
    line-height: 100%;
    cursor: pointer;
  }

  &__options {
    border-radius: 2px;
    border: none;
    box-shadow: 6px 6px 32px rgba(0, 0, 0, 0.08);

    .el-dropdown-menu__item {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $text;
      padding: 0.5rem 2.2rem;

      &.removed {
        color: $error;
      }

      img {
        margin-right: 0.8rem;
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .projects__table {
    margin: 0 -16px;

    .cell {
      display: block;
    }

    .el-table__header-wrapper {
      margin-bottom: 4px;
    }

    .el-table__header {
      display: block;
      width: 100% !important;

      thead {
        display: block;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        background: none;
      }

      th {
        height: auto;

        &:nth-child(1),
        &:nth-child(6) {
          border-radius: 0;
          width: calc(50% - 12px);
          text-align: left;

          .cell {
            padding: 8px 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }
        &:nth-child(6) {
          width: calc(50% + 12px);

          .cell {
            padding-left: 0;
          }
        }

        &:last-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(5) {
          display: none;
        }

        svg {
          display: none;
        }
      }

      .el-select {
        width: 100%;
      }
    }

    .el-table__body-wrapper {
      table,
      tbody {
        display: block;
        width: 100% !important;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding: 16px;
        position: relative;
        margin-bottom: 4px;
      }
      td {
        position: static;
        border-radius: 0;
        padding: 0;
        border: none;
        text-align: left;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(1) {
          width: calc(50% - 12px);
          order: -40;
          font-size: 14px;
          line-height: 20px;
        }

        &:nth-child(6) {
          width: calc(50% - 12px);
          order: -35;
        }

        &:nth-child(7) {
          margin-left: auto;
        }

        &:nth-child(2) {
          width: 75%;
          order: -25;
        }

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }

        .cell {
          padding: 0;
          line-height: 20px;
        }
      }
      .more-options {
        display: block;
        width: 27px;
      }
      .projects__table-actions__chat {
        margin-right: 16px;
        img {
          margin: 0;
          position: static;
        }
      }
    }
  }
  .projects__table-actions {
    padding: 0;
  }
  .remove-message__text {
    .schedule {
      padding: 0;
      margin-top: 10px;
    }
  }
  .projects__employees {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  .projects__nav {
    margin: 0 16px;
  }
}
</style>
